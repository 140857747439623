<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-card flat v-if="payment.status == 'success'">
        <v-img height="200px" src="@/assets/success.png"></v-img>
        <v-card-title>Successful Payment</v-card-title>
        <v-card-text>
          <p>Price: {{ payment.amount }}</p>
          <p>Payment ID: {{ payment.paymentId }}</p>
        </v-card-text>
        <v-progress-circular
            :value="progress"
            size="100"
            color="#39bf88"
            class="mb-4"
        >
          <v-icon size="85" color="#39bf88">mdi-check</v-icon>
        </v-progress-circular>
      </v-card>
      <v-card flat v-else>
        <v-img height="200px" src="@/assets/fail.png"></v-img>
        <v-card-title>Failure to pay</v-card-title>
        <v-card-text>
          <p>Price: {{ payment.amount }}</p>
          <p>Payment ID: {{ payment.paymentId }}</p>
        </v-card-text>
        <v-progress-circular
            :value="progress"
            size="100"
            color="red"
            class="mb-4"
        >
          <v-icon size="85" color="red">mdi-close</v-icon>
        </v-progress-circular>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Payment",
  data() {
    return {
      payment: {
        status: null,
        amount: null,
        paymentId: null,
      },
      progress: 0,
      interval: {}
    }
  },
  mounted() {
    this.payment.status = this.$route.query.status;
    this.payment.amount = this.$route.query.amount;
    this.payment.paymentId = this.$route.query.payment_id;
    this.interval = setInterval(() => {
      this.progress += 100
      if (this.progress == 100) {
        clearInterval(this.interval)
      }
    }, 1000)
  }
}
</script>

<style scoped>

</style>